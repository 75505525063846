import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

class BlogRoll extends React.Component {
  render() {
    const { data, limit } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <ul style={{ listStyle: 'none', margin: 'auto' }} className="blogroll">
        {posts &&
          posts.slice(0, limit ? limit : posts.length).map(({ node: post }) => (
            <li className="is-parent blog-item" key={post.id}>
              <article className={`blog-list-item`}>
                <time
                  className="is-block blog-date"
                  style={{ marginBottom: '2rem' }}
                >
                  {post.frontmatter.date}
                </time>
                <div className="media">
                  <div className="media-left image">
                    <Link to={post.fields.slug}>
                      <PreviewCompatibleImage
                        imageInfo={{ image: post.frontmatter.featuredimage }}
                      ></PreviewCompatibleImage>
                    </Link>
                  </div>
                  <div className="media-content">
                    <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
                  </div>
                </div>
              </article>
            </li>
          ))}
      </ul>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default ({ limit }) => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "YYYY MMM DD")
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => (
      <BlogRoll data={data} count={count} limit={limit} />
    )}
  />
)
