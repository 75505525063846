import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import BlogRoll from '../components/BlogRoll'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import backgroundImage from '../../static/img/home-background.png'

export const IndexPageTemplate = ({ image, intro, company }) => (
  <div>
    <div
      className="full-width-image margin-top-0 container"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: `top left`,
        backgroundAttachment: `fixed`,
        height: `200px`,
      }}
    ></div>
    <section className="container">
      <div className="section">
        <div className="">
          <div>
            <div className="content">
              <div className="columns">
                <div className="column is-12 index-main">
                  <div className="">
                    <PreviewCompatibleImage
                      imageInfo={intro}
                      className="float-image"
                    ></PreviewCompatibleImage>
                  </div>
                  <p className="content top-message">{intro.description1}</p>
                  <p className="content top-message">{intro.description2}</p>
                  <p style={{ fontSize: '12px' }}>{intro.author}</p>
                  <figure className="image top-background-image">
                    <img src={backgroundImage} alt="background"></img>
                  </figure>
                </div>
              </div>
              <hr className="hr"></hr>
              <div className="column is-12 company">
                <ul
                  style={{
                    listStyle: `none`,
                    margin: 0,
                  }}
                >
                  <li className="columns is-mobile">
                    <div className="column is-one-third">Company</div>
                    <div className="column">{company.name}</div>
                  </li>
                  <li className="columns is-mobile">
                    <div className="column is-one-third">Address</div>
                    <div className="column">
                      <a
                        href="https://goo.gl/maps/Zdr6i73BTcJN94yC6"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {company.address}
                      </a>
                    </div>
                  </li>
                  <li className="columns is-mobile">
                    <div className="column is-one-third">Mail</div>
                    <div className="column">
                      <a
                        href={`mailto:${company.mail}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {company.mail}
                      </a>
                    </div>
                  </li>
                  <li className="columns is-mobile">
                    <div className="column is-one-third">Croissant Shop</div>
                    <div className="column">{company.openinghours}</div>
                  </li>
                </ul>
              </div>
              {/*
              <hr className="hr"></hr>
              <div className="column is-12">
                <h3 className="heading-text">News</h3>
                <BlogRoll limit={5} />
                <div className="site-text read-more column is-12 has-text-centered">
                  <Link to="/blog">Read more</Link>
                </div>
              </div>
              */}
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  intro: PropTypes.object,
  company: PropTypes.object,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        intro={frontmatter.intro}
        company={frontmatter.company}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 85) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        intro {
          image {
            childImageSharp {
              fluid(maxWidth: 600, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          description1
          description2
          author
        }
        company {
          name
          address
          mail
          openinghours
        }
      }
    }
  }
`
